.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../images/map-and-gear.jpeg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  min-height: 92vh;
}

.tripPlanningJumbo {
  background: none;
  height: 45vh;
  padding: 2rem;
}

.tripPlanningJumbo > h1 {
  color: white;
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
}

.tripPlanningJumbo > form {
  width: 35vw;
  text-align: center;
  margin: auto;
}

.tripPlanningLabel {
  color: white;
  font-weight: bold;
}

.searchResults {
  display: flex;
  justify-content: center;
}

.placeholderTripSearch {
  width: 66vw;
  background-color: #e9ecef;
  /* background-color: white; */
  margin: 0.5rem;
  border-radius: 5px;
  opacity: 0.95;
}
.BucketList {
  width: 34vw;
  background-color: #e9ecef;
  /* background-color: none; */
  margin: 0.5rem;
  border-radius: 5px;
}

.headerFont {
  font-family: 'Oswald', sans-serif;
  font-weight: 700;
  color: white;
}

/*
 .searchAPI {
  width: 33vw;
  background-color: rgb(219, 212, 212);
  /* text-align:center; */

@media only screen and (max-width: 750px) {
  .tripPlanningJumbo > form {
    width: 80vw;
  }
}
