.userProfileBody {
  display: flex;
  flex-direction: row;
  flex: space-between;
  justify-content: space-around;
}

/* .userProfileBackground {
  background-color: #e9ecef;
  border-radius: 25px;
} */
.CardUser > div {
  background-color: #30638e;

}

.CardUser > .TripCard {
  margin: 1rem;
  width: 23rem;
  padding: 1.2rem;
}
.CardUser > .TripCard > a {
  color: white;
}

.CardUser > .TripCard {
  color: white;
}

.CardUser > .TripCard > div > .badge {
  color: #edae49;
}

.TripSearch > .TripCard > div > .badge {
  background-color: #edae49;
  color: white;
}

.CardUser > .TripCard > div > .complete {
  color: #54b948;
}

.TripSearch > .TripCard > div > .complete {
  background-color: #54b948;
  color: white;
}

.TripSearch > .TripCard {
  margin: 0.5rem auto;
  max-width: 35rem;
  color: gray;
  padding: .7rem;
}

/* .TripSearch > .TripCard > .badge{
  background-color:'blue'
} */

.TripSearch > .TripCard > a {
  color: #003d5b;
}

.Map {
  width: 90%;
  max-width: 1000px !important;
  margin: 0;
}

.BucketList {
  width: 34vw;
  margin:1rem;
}

.BucketCardImage {
  min-width: 20rem;
  height: 12rem;
  opacity: 0.75;
  filter: alpha(opacity=70);
}

.BucketCardText {
  color: #003d5b;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-weight: 600;
  font-size: 2rem;
}

.BucketButton {
  background-color: #edae49;
  border-color: #edae49;
  padding: 0.2rem;
  margin: 0.5rem;
}

@media only screen and (max-width: 750px) {
  .userProfileBody {
    display: block;
  }
  .BucketList {
    max-width: 40rem;
  }
}

.userBody {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.userStats {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap-reverse;
}

.userCountries {
}
/* @media (min-width: 992px) .modal-lg, .modal-xl {
  max-width: 975px;
} */

/* @media all {
  .Map {
    max-width: 1000px;
    margin: 0 auto;
  }
} */
/* style={{flexBasis:'unset'}} */
