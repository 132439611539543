.LogIn {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url('../images/login-background.jpg');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100vh;
  padding: 25vh;
}

.LogIn > h1, .LogIn p{
  color:white;
  font-family: 'Oswald', sans-serif;
  text-align: center,

}

@media only screen and (max-width: 750px) {
  .LogIn {
    padding: 25vh 12vw;
  }
}

