.heading {
  color: white;
  font-family: 'Oswald', sans-serif;
  text-align: center;
  font-size: 3rem;
  background-color: transparent;
}

.background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../../images/map-and-gear.jpeg');
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.dayJumbo {
  opacity: 0.95;
  padding: 0.5rem 0.5rem;
  margin: 1rem 15rem;
}

.card {
  opacity: 1 !important;
  /* width: 13rem; */
  /* height: 12rem; */
  /* min-height: 8rem; */
  padding: 0rem;
}

.cardDeck {
  display: flex;
  flex-direction: column-reverse;
}

.cardNote {
  opacity: 1 !important;
  width: 100%;
  padding: 0rem;
}
.cardNoteBody {
  padding: 0rem;
}

.cardHeader {
  font-weight: bold;
}

@media only screen and (max-width: 750px) {
  .dayJumbo {
    margin: 2rem;
  }
}
